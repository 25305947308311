import { createStore } from 'vuex'

export default createStore({
  state: {

    projekte: [],
    aktuelles: [],

    fetchedProjekte: false,
    fetchedAktuelles: false,

  },
  mutations: {

    setFetchedProjekte(state, data){

      var i
      for (i = 0; i < data.length; i++){

        state.projekte[i] = data[i].acf;

        // console.log(state.projekte[i].titel);

      }
      state.fetchedProjekte = true;

    },

    setFetchedAktuelles(state, data){

      var i
      for (i = 0; i < data.length; i++){

        state.aktuelles[i] = data[i].acf;

        // console.log(state.aktuelles[i].titel);

      }
      state.fetchedAktuelles = true;

    },

  },
  actions: {

    initialFetch(context){

      let baseurl = 'https://projekt210.ch/wordpress/wp-json/acf/v3/';

      // console.log("hello");
      // fetch(baseurl+ 'control.php')

      fetch(baseurl+ 'projekte?per_page=100')
      .then(response => response.json())
      .then(data => context.commit('setFetchedProjekte', data));

      fetch(baseurl+ 'aktuelles')
      .then(response => response.json())
      .then(data => context.commit('setFetchedAktuelles', data));

    }

  },
  modules: {
  }
})
