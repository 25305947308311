<template>
  <div v-if="this.$store.state.fetchedAktuelles" class="">

    <div class="w-full md:w-5/6 lg:w-2/3 mx-auto mb-16">

      <h2 class="text-4xl md:text-6xl">{{aktuelles.titel}}</h2>

      <p class="border-l-2 px-4" v-html="aktuelles.inhalt"></p>

    </div>

  </div>
</template>

<script>
export default {
  name: 'Aktuelles',

  computed: {

    aktuelles() {

      return this.$store.state.aktuelles[0]
    },

  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
