<template>
  <div class="">

    <!-- <h1>What is Updog?</h1> -->
    <img class="rotate mx-auto p-16 w-7/8 md:w-1/2 lg:w-1/3" src="@/assets/projekt210.png" alt="">

  </div>
</template>

<script>
export default {
  name: 'Header',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.rotate {
  animation: rotation 30s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

</style>
