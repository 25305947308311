<template>
  <div class="home bg-black pb-16 p-4">

    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->

    <Header> </Header>

    <Aktuelles> </Aktuelles>

    <Projekte> </Projekte>

  </div>

</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Projekte from '@/components/Projekte.vue'
import Aktuelles from '@/components/Aktuelles.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Projekte,
    Aktuelles
  },
  methods: {

    onload: function () {
      // console.log("hello");
      this.$store.dispatch('initialFetch');

    }

  },

  mounted: function(){

    this.onload();

  },


}
</script>
