<template>
  <div v-if="this.$store.state.fetchedProjekte" class="">

    <div class="w-full md:w-5/6 lg:w-2/3 mx-auto mb-16">

      <h2 class="text-4xl md:text-6xl">Vergangenes</h2>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">

        <div
        class=""
        :key="projekt.inhalt"
        v-for="projekt in projekte">

        <a class="nodeco" :href=" '/' + projekt.shorturl">
          <!-- <router-link :to=" '/' + projekt.shorturl" class=""  tag="div"> -->

          <div class="p-4 relative transform hover:-translate-y-2 transition ease-in-out duration-500 shadow-lg hover:shadow-2xl text-white">

            <img class="object-cover w-full h-80" :src="projekt.keyvisual.url" alt="">

            <div class="mt-4">

              <h3 v-html="projekt.titel" class="text-2xl font-bold text-white"></h3>

              <div v-if="projekt.untertitel !== '' " v-html="projekt.untertitel" class="mb-0 text-lg text-gray-400"></div>

              <div v-else class="mb-0 invisible">Beyond fear, destiny awaits.</div>

              <div v-html="projekt.lead" class="text-base text-justify text-gray-100 mb-6 mt-2">

              </div>

            </div>
          </div>








          <!-- <div class="relative">

          <img class="object-cover w-full h-48 lg:h-64" :src="projekt.keyvisual.url" alt="">

          <div class="cursor-pointer opacity-0 bg-white hover:opacity-50 duration-500 absolute inset-0 z-10 flex justify-center items-center text-4xl text-black font-semibold">
          {{projekt.titel}}
        </div>

      </div> -->

      <!-- </router-link> -->
    </a>
    <!-- <p v-html="projekt.inhalt"></p> -->


  </div>

</div>

</div>

</div>
</template>

<script>
export default {
  name: 'Projekte',

  methods: {

    compare(a, b) {
      if (a.date > b.date) {
        return -1;
      }
      if (a.date < b.date) {
        return 1;
      }
      // a muss gleich b sein
      return 0;
    },

  },

  computed: {

    projekte() {
      // console.log(this.$store.state.projekte[2].shorturl);
      return this.$store.state.projekte.slice().sort(this.compare)
    },

  },

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.nodeco {
  text-decoration: none !important;
}

</style>
